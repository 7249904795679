export default {
  isoName: 'pt-BR',
  common: {
    admins_page: {
      success_save_admin: 'Administrador salvo com sucesso!',
      delete_success_admins: 'Administrador(es) excluído(os) com sucesso!'
    },
    time_group_page: {
      time_group_success_save: 'Grupo de horário salvo com sucesso!',
      success_delete_group: 'Grupo excluído com sucesso!',
      success_delete_batch_group:
        'Grupo(s) de horários excluído(s) com sucesso!'
    },
    country: 'País',
    previously: 'ANTERIORMENTE',
    general_error: 'Ocorreu um erro no servidor',
    dashboard: 'Dashboard',
    report: 'Relatório | Relatórios',
    documentation: 'Documentação',
    changelog: 'Changelog',
    status: 'Status',
    check_account: 'Verificar conta',
    premium: 'Premium',
    artificial_intelligence: 'Inteligência artificial',
    contexts: 'Contextos',
    quota: 'Cota da API',
    start_hour: 'Hora Inicial',
    initial: 'Inicial',
    final: 'Final',
    end_hour: 'Hora Final',
    security_privacy: 'Segurança e privacidade',
    flow: 'Fluxo',
    integrate: 'Integrar',
    channel: 'Canal | Canais',
    batch: 'Envio em lote',
    contact: 'Contato | Contatos',
    config: 'Configuração | Configurações',
    help: 'Ajuda',
    about: 'Sobre',
    turn_on: 'Ligar',
    list_title: 'Título da lista',
    see_items: 'Ver items',
    multiproduct: 'Multiprodutos',
    indicator: 'Indicador | Indicadores',
    storage: 'Armazenamento',
    monitoring: 'Monitoramento',
    name: 'Nome',
    ad_account: 'Conta de Anúncio',
    url: 'URL',
    clone: 'Clonar',
    resend: 'Reenviar',
    message_templates: 'Modelos de mensagem',
    models: 'Modelo | Modelos',
    contents: 'Conteúdo',
    title: 'Título',
    caption: 'Subtítulo',
    message_box: 'Caixa de mensagem',
    ending_message: 'Mensagem de finalização',
    light: 'Clara',
    dark: 'Escuro',
    slide: 'Deslizar',
    increase: 'Aumentar',
    message: 'Mensagem',
    gradually_appear: 'Aparecer gradualmente',
    increase_rotated: 'Aumentar rotacionado',
    text: 'Texto',
    generate_token_auth: 'Gerar token de autenticação',
    generate_api_key: 'Gerar ApiKey',
    appearance: 'Aparência',
    main_color: 'Cor principal',
    color: 'Cor',
    button_color: 'Cor dos botões',
    alert_color: 'Cor dos alertas',
    font_color: 'Cor da fonte',
    enter_your_message: 'Digite sua mensagem',
    header: 'Cabeçalho',
    body: 'Corpo',
    value: 'Valor',
    parameter: 'Parâmetro | Parâmetros',
    email: 'E-mail',
    recovery_email: 'E-mail de recuperação',
    login_email: 'E-mail de login',
    attendance: 'Atendimento | Atendimentos',
    agent: 'Agente | Agentes',
    conversation: 'Conversa | Conversas',
    export: 'Exportação',
    variable: 'Variável | Variáveis',
    audit: 'Auditoria',
    password: 'Senha',
    redefine_password: 'Redefinir senha',
    current_password: 'Senha atual',
    new_password: 'Senha nova',
    confirm_password: 'Confirmar senha nova',
    remember: 'Lembrar',
    log_in: 'Entrar',
    contact_center: 'CONHEÇA O NOVO CONTACT CENTER',
    message_extract: 'Extrato de Mensagens',
    team: 'Equipe | Equipes',
    pause: 'Pausa | Pausas',
    tabulation: 'Tabulação | Tabulações',
    tags: 'Tag | Tags',
    aplication: 'Aplicação',
    icon: 'Ícone',
    form_title: 'Título do formulário',
    button: 'Botão',
    buttons: 'Botões',
    button_text: 'Texto do botão',
    image: 'Imagem',
    apply: 'Aplicar',
    clean: 'Limpar',
    position: 'Posição',
    label: 'Label',
    animation: 'Animação',
    customization: 'Customização',
    multichannel: 'Multicanal',
    import_contacts: 'Importar contatos',
    input_fields: 'Campos de entrada',
    time_group: 'Grupo de horários',
    api: 'API',
    option_title: 'Título da opção',
    product_id: 'Id do produto',
    approved: 'Aprovado',
    in_analysis: 'Em análise',
    pending: 'Pendente',
    unsynchronized: 'Não sincronizado',
    refused: 'Recusado',
    not_applicable: 'Não se aplica',
    rest: 'REST',
    third: 'Terceiros',
    seconds: 'Segundos',
    minutes: 'Minutos',
    rd_station: 'RD Station (beta)',
    telephone_exchange: 'Central telefônica',
    integration: 'Integração | Integrações',
    client_secret: 'Chave secreta',
    app_id: 'Identificador do aplicativo',
    user_group: 'Grupo de usuários',
    additional_fields: 'Campos adicionais',
    word_filter: 'Filtro de palavras',
    remote_support: 'Suporte remoto',
    support: 'Suporte',
    custom_variables: 'Varíaveis personalizadas',
    administrator: 'Administrador | Administradores',
    settings: 'Configurações',
    page: 'Página | Páginas',
    hsm: 'HSM',
    hsm_template: 'Modelos de mensagem',
    login: 'Login',
    recover_password: 'Recuperar senha',
    rules: 'Regra | Regras',
    attention: 'Atenção',
    automatic_answer: 'Resposta automática',
    attendance_automatic: 'Atendimento automático',
    initial_message: 'Mensagem inicial',
    default_team: 'Equipe padrão',
    qrcode: 'QrCode',
    enable_qrcode: 'Ativar QrCode',
    connected: 'Conectado',
    disconnected: 'Desconectado',
    starting_process: 'Iniciando processo',
    pending_auth: 'Pendente',
    smtp_error: 'Erro SMTP',
    error_imap_and_smtp: 'Erro SMTP e IMAP',
    error_imap: 'Erro IMAP',
    forgot_password: 'Esqueceu a senha?',
    login_title: 'Área de login',
    language: 'Idioma',
    filter: 'Filtro',
    search: 'Pesquisar',
    description: 'Descrição',
    method: 'Método',
    auth_method: 'Método de Autenticação',
    all: 'Todos',
    agents_only: 'Apenas Agentes',
    admins_only: 'Apenas Administradores',
    none: 'Nenhum',
    rows_per_page: 'Linhas por página',
    tip: 'Dica | Dicas',
    type: 'Tipo | Tipos',
    default: 'Padrão',
    version: 'Versão',
    number: 'Número | Números',
    spacing: 'Espaçamento | Espaçamentos',
    send_schedule: 'Enviar horário',
    send_media_with_link: 'Enviar mídias com link',
    able: 'Habilitado',
    disabled: 'Desabilitado',
    token: 'Token',
    botname: 'Nome do bot',
    botlink: 'link do bot',
    reauthentication: 'Reautenticação',
    receptive: 'Receptivo',
    teams_gtw: 'Habilitar Teams Gateway Interface (Beta)',
    enable_batch_delivery: 'Habilitar envio em lote',
    account_name: 'Nome da conta',
    ra_page_id: 'Empresa RA',
    ra_success:
      'Autenticado com sucesso no Reclame Aqui. Escolha a página abaixo.',
    apikey_generate_success: 'Sua apikey foi gerada com sucesso.',
    account_password: 'Senha da conta',
    control: 'Controle',
    business_hours: 'Horário comercial',
    interval: 'Intervalo',
    update_interval: 'Intervalo padrão',
    post: 'Postagem',
    comment: 'Comentário | Comentários',
    period: 'Período',
    characters: 'Caracteres',
    day: 'Dia | Dias',
    file: 'Arquivo | Arquivos',
    hour: 'Hora | Horas',
    week: 'Semana | Semanas',
    without_permission: 'Sem permissão',
    yes: 'Sim',
    activate: 'Ativar',
    remove_session: 'Remover sessão',
    no: 'Não',
    provider: 'Provedor',
    port: 'Porta',
    list: 'Lista',
    card: 'Cartão',
    host: 'Host',
    notification: 'Notificação',
    required: 'Obrigatório',
    protocol: 'Protocolo',
    user: 'Usuário | Usuários',
    announcement: 'Comunicado',
    time: 'Tempo',
    hash: 'Hash',
    message_body: 'Corpo de mensagem',
    media_type: 'Tipo de mídia',
    header_type: 'Tipo de cabeçalho',
    type_action: 'Tipo de ação',
    add: 'Adicionar',
    add_product: 'Adicionar produto',
    add_option: 'Adicionar opção',
    add_session: 'Adicionar sessão',
    add_variable: 'Adicionar variável',
    bold: 'Negrito',
    italic: 'Itálico',
    strike: 'Riscado',
    underline: 'Sublinhado',
    strikethrough: 'Tachado',
    paste: 'Colar',
    right: 'Direita',
    optional: 'Opcional',
    introduction: 'Introdução',
    function: 'Função',
    left: 'Esquerda',
    close: 'Fechar',
    conclude: 'Concluir',
    advance: 'Avançar',
    footer: 'Rodapé',
    upload_icon: 'Ícone de upload',
    copy_token_auth: 'Copiar token de autenticação',
    icon_color: 'Cor do ícone',
    other: 'Outro | Outros',
    copy_identifier: 'Copiar identificador',
    copy_webhook: 'Copiar webhook',
    authenticate: 'Autenticar',
    copy: 'Copiar',
    activation_code: 'Código de ativação',
    restart: 'Reiniciar',
    category: 'Categoria',
    visibility: 'Visibilidade',
    example_template: 'Modelo de exemplo',
    preview: 'Pré-visualização',
    previous_page: 'Voltar para a página anterior',
    select: 'Selecione',
    login_or: 'ou',
    of: 'de',
    oauth_redirect_url: 'URI de redirect',
    webhook_url: 'URL de retornos de chamada de notificação',
    aware: 'Ciente',
    unlock_for_trust: 'Desbloquear em confiança',
    access_now: 'Acessar agora',
    authenticationMethod: 'Método de autenticação',
    mode: 'Tipo de função',
    test: 'Testar',
    result: 'Resultado',
    custom: 'Customizável',
    script_test: 'Teste de Script',
    lang: {
      'pt-br': 'Português',
      es: 'Espanhol',
      en: 'Inglês'
    },
    list_colors: {
      yellow: 'Amarelo',
      blue: 'Azul',
      green: 'Verde',
      grey: 'Cinza',
      purple: 'Roxo',
      brown: 'Marrom',
      red: 'Vermelho',
      violet: 'Violeta',
      pink: 'Rosa',
      black: 'Preto'
    },
    components: {
      facebook_reaction: 'Reação do Facebook',
      attachment: 'Anexo',
      receive_attachment: 'Receber anexo',
      menu: 'Menu',
      webchat_event: 'Evento webchat',
      location: 'Localização',
      service_note: 'Nota de atendimento',
      condition: 'Condição',
      input: 'Entrada de dados',
      template_message: 'Modelo de mensagem'
    },
    actions: {
      self: 'Ação | Ações',
      edit: 'Editar',
      remove: 'Remover',
      save: 'Salvar',
      back: 'Voltar',
      cancel: 'Cancelar'
    },
    messages: {
      required_office365: 'Apenas para usuários office365',
      image_size: 'A imagem deve conter a dimensão de 192x192 pixels',
      auth_verify: 'Verificando autenticação',
      invalid_character: 'Caractere inválido!',
      licence_limit_per_channel_reached: 'Limite de licença por canal atingido',
      channel_already_exists_in_another_licence:
        'Canal já existe em outra licença',
      email_settings_not_found: 'Configurações de e-mail não encontrados',
      reauthentication_required: 'Reautenticação necessária',
      flow_invalid:
        'O fluxo definido possui componentes não suportados pelo canal',
      automatic_spacing:
        'Espaçamento automático nas mensagens evita banimento do Whatsapp',
      required: '{name} é obrigatório!',
      can_be_no_space: '{name} não pode ter espaços!',
      view_mode: 'Modo de exibição {name}',
      invalid: '{name} é inválido!',
      incomplete_phone_number: 'DDI + DDD é obrigatório',
      no_data: 'Nenhum {name} cadastrado!',
      no_result: 'Nenhum resultado encontrado!',
      url_webhook: 'URL do webhook não encontrada!',
      copy: 'Copiado com sucesso!',
      are_you_sure: 'Você tem certeza?',
      success: 'Realizado com sucesso!',
      restart_channel: 'Você realmente deseja reiniciar?',
      channel_period:
        'Defina quantos dias deseja exibir no histórico de comentários',
      error: 'Ocorreu um erro!',
      error_email: 'Ocorreu um erro na autenticação do e-mail.',
      password_not_match: 'Senhas não conferem',
      session_end: 'Finalizar sessão',
      message_color: 'Cor da mensagem do usuário',
      edit_user_information: 'Editar informações de usuário',
      you_really_delete: 'você realmente deseja excluir?',
      really_want_to_resend:
        'Você realmente deseja reenviar o modelo de mensagem para aprovação?\nA análise pode levar até 24hs.',
      redirect_to_update:
        'Não foi possível verificar todos os canais a serem reenviados. Você será redirecionado para a tela de edição para selecionar os canais manualmente. Deseja continuar?',
      you_really_end_service: 'Deseja realmente encerrar seu atendimento?',
      button_customization: 'Personalização do botão',
      chat_customization: 'Personalização do chat',
      can_i_help_you: 'Posso ajudar?',
      only_cell: 'Apenas em celulares',
      character_limit: 'Límite de caracteres por mensagem',
      welcome_message: 'Mensagem de boas vindas',
      select_default_image: 'Selecione uma imagem padrão para o chat',
      select_image_button: 'Selecione uma imagem para o botão',
      selected_platform:
        'A plataforma selecionada não pode usar um fluxo que contém os seguintes componentes',
      unlock_for_trust:
        'O desbloqueio em confiança terá duração de 48 horas. Por favor, entrar em contato com o responsável.',
      alert_newsletter:
        'Alertas do formulário inicial que são exibidos quando um campo obrigatório não é preenchido ou quando uma informação é inserida em um formato incorreto.'
    }
  },
  signin_message: {
    login_back: 'Voltar para login',
    auth_remote_message:
      'O usuário não tem mais permissão de login remoto, redefina a senha para ter acesso ao sistema.',
    username_password_match: 'Usuário e/ou senha não conferem',
    expired_password: 'Sua senha expirou, por favor cadastre uma nova senha!',
    lock_prediction:
      'Seu sistema será bloqueado em {days} dia(s). Para evitar o bloqueio, por favor entrar em contato com o responsável.',
    locked_system:
      'Seu sistema foi bloqueado. Para continuar acessando normalmente a plataforma, por favor entrar em contato com o responsável.'
  },
  marketplace_screen: {
    messages: {
      expand_power: 'Expanda o seu poder com integrações de apps',
      marketplace_collection:
        'Nosso marketplace é uma coleção de apps para expandir o poder da plataforma e melhorar o seu desempenho.',
      choose_below:
        'Escolha abaixo o modo que deseja iniciar a sua nova integração.'
    }
  },
  hsm_screen: {
    messages: {
      image_size_limit: 'Imagens com mais de 5mb não são permitidas',
      body_example: 'Exemplo de corpo de texto',
      enable_device: 'Você deseja habilitar o template para o dispositivo?',
      disable_device: 'Você deseja desabilitar o template para o dispositivo?',
      no_result_channel: 'Nenhum canal adicionado!',
      no_result_dialog: 'Nenhum canal Dialog encontrado!',
      updated_variables: 'Variáveis atualizadas!',
      message_required: 'Corpo de mensagem é obrigatório!',
      template_example_message:
        'Envie o seu modelo com exemplos para aumentar a chance de aprovação',
      alert_example_message:
        'Os exemplos serão enviados para o Facebook como parte do processo de aprovação dos modelos de mensagens. Se nenhum exemplo for informado, os modelos poderão ser rejeitados pelo Facebook.'
    },
    no_registration_variable:
      'Este cadastro não possui as variáveis preenchidas, corrija para utilizar normalmente!',
    type_repetition: 'Os valores de tipo em tipo de ação estão repetidos!',
    status_error: 'Erro',
    status_reject: 'Rejeitado',
    in_analysis: 'Esse modelo está sendo analisado por uma equipe da Meta.',
    type_to_replace: 'Digite para substituir:',
    template_exists: 'Já existe um modelo com esse nome.',
    select_template: 'Selecione um template para iniciar.',
    select_models: 'Selecione um dos modelos para continuar',
    choose_model: 'Escolher modelo',
    button_type_quick_reply: 'Respostas rápidas',
    button_type_action: 'Ações',
    phone: 'Telefone',
    head_type_none: 'Nenhum',
    head_type_text: 'Texto',
    head_type_image: 'Imagem',
    head_type_document: 'Documento',
    head_type_video: 'Video',
    message_button_limit: 'Limite máximo de botões atingido.',
    message_option_limit: 'Limite máximo de opções atingido.',
    message_section_limit: 'Limite máximo de sessões atingido.',
    message_example_not_found: 'Informe um exemplo para cada variável.',
    message_device_not_found: 'Nenhum canal foi ativado.'
  },
  hsm_categories: {
    account_update: 'Atualização da conta',
    payment_update: 'Atualização de pagamento',
    personal_finance_update: 'Atualização de finanças pessoais',
    shipping_update: 'Atualização de envio',
    reservation_update: 'Atualização de reserva',
    issue_resolution: 'Resolução de problemas',
    appointment_update: 'Atualização de compromisso',
    transportation_update: 'Atualização de transporte',
    ticket_update: 'Atualização de bilhete',
    alert_update: 'Alerta de atualização',
    auto_reply: 'Resposta automática'
  },
  hsm_visibility: {
    all: 'Todos',
    agents_only: 'Apenas Agentes',
    admins_only: 'Apenas Administradores',
    none: 'Nenhum'
  },
  channel_screen: {
    my_business: {
      configuration_auth: 'Configuração de Autenticação'
    },
    messages: {
      generic_type: 'Tipo do canal genérico',
      webhook: {
        inbound: 'Hook de entrada',
        outbound: 'Hook de saída'
      },
      include_channel: 'Incluir Canal',
      edit_channel: 'Editar Canal',
      check_account: 'Conta do Instagram Direct é comercial!',
      webhook_register:
        'O campo Webhook ficará disponível para visualização após o cadastro com sucesso deste canal',
      configuration_bsp: 'Configuração do BSP',
      generic_channel_edit: 'Editar Canal Genérico (Beta)',
      generic_channel_add: 'Adicionar Canal Genérico (Beta)',
      wait_one_minutes: 'Espere 1 minuto para realizar a modificação',
      enable_channel: 'Você deseja habilitar esse canal?',
      session_open_channel_remove:
        'Existem sessões em aberto para este canal, é necessário remover as sessões antes de finalizar a exclusão',
      disable_channel: 'Você deseja desabilitar esse canal?',
      enable_receptive: 'Você deseja habilitar o receptivo?',
      disable_receptive: 'Você deseja desabilitar o receptivo?',
      token_generate:
        'Ao gerar um novo token, os antigos deixarão de funcionar. Você realmente deseja gerar um novo token?',
      apikey_generate:
        'Ao gerar uma nova ApiKey, os antigos deixarão de funcionar. Você realmente deseja gerar um novo ApiKey?',
      apikey_info: 'Espere 1 minuto enquanto atualizamos sua apikey.'
    },
    webchat: {
      ask_information: 'Pedir informações antes de iniciar o chat',
      display_button_message:
        'Exibir mensagem do botão depois de quantos segundos?',
      display_button_chat: 'Exibir o botão do chat depois de quantos segundos?',
      choose_animation: 'Escolha a animação da exibição do chat',
      keep_keyboard: 'Manter o teclado aberto após enviar mensagem?',
      close_chat_after: 'Fechar chat após usuário ter a sessão encerrada?',
      allow_ballon: 'Permitir que mensagem do balão seja fechada',
      image_dimension:
        'O valor informado para o campo icone não é uma dimensão de imagem válida.',
      display_welcome_message:
        'Exibir mensagem de boas vindas ao iniciar o webchat',
      show_message_chat: 'Exibir mensagem ao lado do chat',
      enable_active_webchat: 'Habilitar Webchat ativo (Beta)',
      information_starting:
        'Precisamos de algumas informações antes de iniciar o atendimento',
      display_online:
        'Exibir como online se um ou mais agentes estiverem conectados.',
      select_service:
        'Selecione os atendimentos que exibirão o chat online caso tenha agentes conectados. Deixe em branco para todos os atendimentos',
      webchat_users_notification:
        ' Este recurso possibilita uma conversa bidirecional com usuários do Webchat, como recebimento de notificações de mensagens novas, historicos e cadastro unificados. Uma vez habilitado, o Webchat oferece ao usuário a opção de instalar no seu home-screen funcionando como um aplicativo.',
      select_image_webchat:
        'Selecione uma imagem para o ícone do Webchat A imagem deve conter a dimensão de 512x512 pixels.'
    },
    teams: {
      microsoft_app_id: 'Microsoft App ID',
      microsoft_app_password: 'Microsoft App Password'
    }
  },
  webchat: {
    tip:
      'Você precisará inserir o código uma vez em cada página em que deseja exibir o Webchat. Insira o código acima na tag (ou na parte superior do corpo) da sua página. Se precisar de um link direto para compartilhar com seus clientes, use o link abaixo.'
  },
  active_contact: {
    active_contact_title:
      'Escolha o tipo de modelo de mensagem que deseja utilizar para iniciar uma nova conversa!',
    active_contact_confirmation: 'Confirme o modelo de mensagem escolhido!',
    models_not_found: 'Nenhum modelo foi encontrado.',
    labels: {
      campaigns: {
        label: 'Equipes',
        placeholder: 'Selecione uma equipe'
      },
      channels: {
        label: 'Canais',
        placeholder: 'Selecione um canal'
      },

      active_sessions: 'Sessões ativas',
      custom_variable:
        'O conteúdo das variáveis presentes no modelo podem ser personalizadas utilizando os campos abaixo',
      variables: 'Variáveis',
      filter: 'Filtro',
      name: 'Nome',
      message: 'Mensagem',
      category: 'Categoria'
    },
    customer_care: {
      title: 'Customer Care',
      description: 'Tempo restante desde a última mensagem do usuário'
    },
    click_to_whatsapp: {
      title: 'Click to WhatsApp (CTWA)',
      description: 'Conversa iniciada por campanha'
    },
    service: {
      title: 'Serviço',
      description: 'Conversa iniciada pelo negócio',
      tip: 'Permite que você resolva dúvidas dos clientes.'
    },
    marketing: {
      title: 'Marketing',
      description: 'Conversa iniciada pelo negócio',
      tip:
        'Permitem que você atinja uma ampla gama de objetivos, desde gerar reconhecimento até impulsionar vendas e redirecionar clientes. Os exemplos incluem anúncios de novos produtos, serviços ou recursos, promoções/ofertas direcionadas e lembretes de abandono de carrinho.'
    },
    utility: {
      title: 'Utilidade',
      description: 'Conversa iniciada pelo negócio',
      tip:
        'Permitem acompanhar ações ou solicitações do usuário. Os exemplos incluem confirmação de aceitação, gerenciamento de pedidos/entregas (por exemplo, atualização de entrega), atualizações ou alertas de conta (por exemplo, lembrete de pagamento) ou pesquisas de feedback.'
    },
    authentication: {
      title: 'Autenticação',
      description: 'Conversa iniciada pelo negócio',
      tip:
        'Permitem que você confirme a identidade do usuário usando códigos de acesso descartáveis em várias etapas do processo de login (por exemplo, verificação e recuperação da conta ou desafios de integridade).'
    },
    buttons: {
      cancel: 'Cancelar',
      next: 'Avançar',
      back: 'Voltar',
      send: 'Enviar',
      apply: 'Aplicar',
      clear: 'Limpar',
      confirm: 'Sim, confirmar',
      send_free_text: 'Enviar texto livre'
    },
    dialog: {
      title: 'Deseja abrir uma nova sessão?',
      description:
        'Você já possui uma sessão ativa em andamento. Ao iniciar uma nova sessão, será gerada uma cobrança adicional.'
    }
  },
  authentication_template: {
    example_message: 'Olá, seu código de autenticação é <b>{{1}}</b>.',
    advisory_message: 'Para sua segurança não o compartilhe.',
    button_message: 'Copiar código',
    footer_message: 'Este código expira em',
    example_footer: 'Este código expira em 10 minutos',
    code_delivery: {
      title: 'Entrega do código',
      description:
        'A Meta persistirá na tentativa de entrega de uma mensagem ao usuário do WhatsApp durante o período designado como tempo de vida da mensagem. Por padrão, os modelos de autenticação têm um tempo de vida de 10 minutos. Se a entrega não for bem-sucedida dentro desse intervalo, as tentativas serão interrompidas e a mensagem será descartada.'
    },
    copy_code: {
      title: 'Copiar código',
      description:
        'Autenticação básica simplificada. Seus clientes podem facilmente copiar e colar o código em seu aplicativo para garantir acesso rápido e seguro.',
      button_info:
        'Os clientes tocam no botão para copiar e colar o código no próprio app.',
      button_tip:
        'A opção "Copiar código" funciona melhor para clientes que solicitam o código em um dispositivo que não contém sua conta do WhatsApp.'
    },
    text_button: {
      title: 'Texto do botão',
      description:
        'Você tem a possibilidade de personalizar o texto do botão conforme suas preferências e necessidades, adaptando-o da maneira que mais lhe convier.'
    },
    message_content: {
      title: 'Conteúdo da mensagem',
      description:
        'Não é possível editar o conteúdo dos modelos de mensagem de autenticação. Você pode adicionar mais conteúdo das opções abaixo.'
    },
    labels: {
      add_advisory: 'Adicionar recomendação de segurança',
      add_expires: 'Adicione o tempo de expiração do código',
      expires_in: 'Expira em',
      expires: 'Tempo de expiração',
      variable: 'Variável',
      placeholder: 'Placeholder'
    }
  },
  forgot_password: {
    success:
      'Foi enviado um link para a mudança de senha para o email {email}, verifique nos próximos minutos a sua caixa de entrada.'
  },
  errors: {
    only_json_file: 'Somente arquivos Json são permitidos',
    access_denied: 'Acesso negado',
    access_denied_subtitle: 'Você não tem permissão para acessar esta página.',
    not_found: 'Página não encontrada',
    not_found_subtitle: 'A página solicitada não foi encontrada.',
    failed_server_remote:
      'Falha ao autenticar com Servidor Remoto. Autenticador sem atributo de e-mail configurado.',
    failed_authenticate_remote_client:
      'Falha ao autenticar com Servidor Remoto. Client {host} não configurado/identificado.',
    failed_authenticate_unaccredited:
      'Falha ao autenticar com Servidor Remoto. Usuário não credenciado.',
    failed_authenticate_profile:
      'Falha ao autenticar com Servidor Remoto. Usuário sem perfil definido.',
    authenticate_remote_not_possible:
      'Não é possível se autenticar com servidor remoto no momento.',
    no_active_team: 'Você não possui uma equipe ativa',
    forgot_password_invalid_email:
      'Não conseguimos encontrar nenhum endereço de e-mail para recuperação de senha do usuário informado.',
    forgot_password_user_not_found:
      'Não conseguimos encontrar nenhum usuário com o endereço de e-mail informado.',
    service_unavailable: 'Serviço indisponível',
    user_cannot_reset_password:
      'Para alterar a senha de acesso, entre em contato com o administrador do provedor de autenticação remota.',
    user_blocked_to_access: 'Seu login está temporariamente bloqueado!',
    user_or_password_wrong: 'Usuário e/ou senha não conferem',
    user_or_password_wrong_with_recaptchav2: 'Usuário e/ou senha não conferem',
    current_password_is_invalid: 'Senha atual é inválida!',
    passwords_do_not_match: 'As senhas informadas não conferem!',
    password_dont_have_min_length:
      'Senha não possui o tamanho mínimo de {minLength} caracteres.',
    password_dont_have_rules:
      'Senha deve possuir no mínimo uma letra, um número e um caractere especial.',
    password_have_numeric_sequence:
      'Não é possível inserir uma sequência numérica dentro da senha. Ex.: ..012.., ..654..',
    password_have_chars_repeated:
      'Não é possível inserir caracteres repetidos dentro da senha. Ex.: ..aa.., ..22.., ..--..',
    password_dont_register_again:
      'Você não pode registrar uma senha utilizada anteriormente.',
    flow_not_exist: 'O fluxo selecionado não existe!',
    ra_auth_error: 'Erro ao se autenticar na API do Reclame Aqui!',
    channel_not_provided: 'Canal não encontrado',
    channel_generate_apikey_error: 'Tivemos um problema ao gerar a apikey',
    recaptcha_low_score: 'Por favor, verifique o Recaptcha.',
    no_recaptcha_token_response: 'Erro ao verificar recaptcha.',
    max_sessions: 'Erro ao exceder o limite de sessões.',
    max_product_items: 'Erro ao exceder o limite de produtos.',
    duplicate_product_id: 'Erro ao enviar ids de produtos duplicado.',
    update_error_in_less_than_30_days:
      'Modelo de mensagem não pode ser atualizado mais de 10 vezes em menos de 30 dias.',
    update_error_in_less_than_24_hours:
      'Modelo de mensagem não pode ser atualizado mais de uma vez em menos de 24 horas.',
    not_found_userhub:
      'Não foi encontrado o usuário do canal, verifique se o canal está habilitado.',
    not_found_meta_id: 'O modelo de mensagem não foi encontrado na Meta.'
  },
  invalid: {
    login_disabled:
      'Seu login está desabilitado. Entre em contato com seu administrador!',
    max_agent_simult: 'Atingiu o limite de agentes simultâneos',
    out_of_hours: 'Agente fora do horário de trabalho!',
    empty_campaign: 'Você não possui uma equipe ativa!'
  }
};
